import api from './api'
export default {
    signup(data,source,clickid,refid){
        return api.execute(`post`,`/signup?source=`+source+'&clickid='+clickid+'&refererid='+refid,data)
    },
    login(data){
        return api.execute(`post`,`/login`,data)
    },
    authenticate(){
        return api.execute(`get`,`/alive`)
    },
    logout(){
        return api.execute(`get`,`/logout`)
    },
    sendForgotPasswordMail(data){
        return api.execute(`post`,`/forgetpwd`,data)
    },
    changePassword(data){
        return api.execute(`post`,`/changepwd`,data)
    },
    getAllUsers(){
        return api.execute(`get`,`/manager/getusers`)
    },
    getUserInfo(data, payload={}){
        let path = `?currentPage=${data.currentPage || ''}&pageSize=${data.pageSize || ''}&sortBy=${data.sortBy || ''}&order=${data.order || ''}`
        return api.execute(`post`,`/moderator/userInfo/getUserInfo`+ path, payload)
    }, 
    getCountryList(){
        return api.execute(`get`,`/moderator/userInfo/getCountryList`)
    },
    changeStatus(data){
        return api.execute(`post`,`/manager/changestatus`,data)
    },
    getPlanInfo(){
        return api.execute(`get`,`/plan`) 
    },
    getPlanList(){
        return api.execute(`get`,`/planlist`) 
    },
    checkOut(data){
        return api.execute(`post`,`/checkout`,data)
    },
    cancelSubcscription(){
        return api.execute(`get`,`/cancelsub`) 
    },
    sendOtp(){
        return api.execute(`get`,`/emailotp `) 
    },
    verifyOtp(data){
        return api.execute(`post`,`/emailverify `,data)
    },
    acceptCall(){
        return api.execute(`get`,`/accept `)
    },
    profile(data){
        return api.execute(`post`,`/setting/profile `,data)
    },
    getRefCount(){
        return api.execute(`get`,`/aff/referal `)
    },
    visitedUser(){
        return api.execute(`get`,`/visit/pricepage`)
    },
    editUser(data){
        return api.execute(`post`,`/manager/edituser`,data)
    }
}