import EventEmitter from 'events';
import UserController from "../../../services/userController"
// 'loggedIn' is used in other parts of application. So, Don't forget to change there also
const userlogin = 'islogged';
const loginExpiryKey = 'tokenExpiry';
const Userinfo = 'userinfo';

class Auth extends EventEmitter {

    localLogin(authResult) {
        this.tokenExpiry = new Date();
        localStorage.setItem(loginExpiryKey, this.tokenExpiry);
        localStorage.setItem(userlogin, 'true');
        localStorage.setItem(Userinfo, JSON.stringify({
            email : authResult.email,
            role : authResult.role,
            userid: authResult.userid,
            username: authResult.username,
        }));
    }

   async Logout() {
        localStorage.removeItem(loginExpiryKey);
        localStorage.removeItem(userlogin);
        localStorage.removeItem(Userinfo);
        let response = await UserController.logout()
    }
    ForcefullyLogout(){
        localStorage.removeItem(loginExpiryKey);
        localStorage.removeItem(userlogin);
        localStorage.removeItem(Userinfo);
        window.location = '/'
    }
    isAuthenticated() {
        return (
            new Date(Date.now()) !=  new Date(localStorage.getItem(loginExpiryKey)) &&
            localStorage.getItem(userlogin) === 'true'
        );
    }
    checAlive(){
        return ( this.Auth()
        );
    }
    async Auth() {
        let  response = await UserController.authenticate()
        if(response == true){
            return true;
        }
        else{
          return false
        }
    }
}

export default new Auth();
