import api from './api'
export default {
    getIncedent(data){
        return api.execute(`GET`,`/dashboard/incedent`+data)
    },
    getEvents(data){
        return api.execute(`GET`,`/dashboard/events`+data)
    },
    getMonitorReport(){
        return api.execute(`GET`,`/dashboard/monitors`)
    },
    timelineData(data){
        return api.execute(`POST`,`/dashboard/timeline`,data)
    },
    getlogsdata(data){
        return api.execute(`POST`,`/dashboard/reslogs`,data)
    },
    keywordLog(id){
        return api.execute(`GET`,`/hblogs/`+id)
    },
    getPageloadData(data){
        return api.execute(`POST`,`/pageload`,data)
    },
    getPageloadImage(data){
        return api.execute(`POST`,`/pageload/image`,data)
    },
    getUptime(){
        return api.execute(`GET`,`/dashboard/stats`)
    },
    getUptimeByMid(data){
        return api.execute(`GET`,`/dashboard/stats`+data)
    },
}