<template>
  <div id="app">
    <!-- Loader starts-->
    <div class="loader-wrapper" :class="{'loderhide' : !show }">
      <div class="loader loader-7">
        <div class="line line1"></div>
        <div class="line line2"></div>
        <div class="line line3"></div>
      </div>
    </div>
    <!-- Loader ends-->

    <!--<Main/>-->
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "app",
  data() {
    return {
      show: true
    };
  },
  mounted() {
    this.timeOut();
  },
  methods: {
    timeOut() {
      // this.basketAddSuccess = true;
      var self = this;
      setTimeout(function() {
        self.show = false;
      }, 1000);
    }
  }
};
</script>

<style lang="scss" scoped>
.loader-wrapper.loderhide {
  display: none;
}
</style>
<style>
.link_txt{
    color: blue;
    cursor: pointer;
}
tr{
  box-shadow: 0px 0px 1px 3px rgba(239, 238, 238, 0);
}
@media (max-width: 767.98px){
  tr{
  box-shadow: 0px 0px 1px 3px rgb(93, 92, 92);
}
}
</style>

<style>
th{
    text-align: unset !important;
}
.table th, .table td {
    padding: 0.25rem !important;
}
.google_btn{
  cursor: pointer;
  border:1px solid rgb(88, 88, 245);
  border-radius: 5px;
}
.google_btn:hover{
  border:2px solid rgb(0, 0, 255);
}
.cust_txt {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
}
.cust_txt:hover {
    text-decoration: underline;
    max-width: auto;
    text-overflow: clip;
    white-space: normal;
    overflow: auto;
}
.res_text {
  display: inline-block;
  max-width: 30em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
  cursor: pointer;
}
.res_text:hover {
  max-width: auto;
  text-overflow: clip;
  white-space: normal;
}
.copy_cursor:hover{
  cursor: copy;
}
.card .card-header {
  padding: 20px !important;
}
.cursor_pointer{
  cursor: pointer;
}
</style>
