<template>
  <div class="container-fluid">
    <div class="page-header">
      <div class="row">
        <div class="col-lg-6">
          <div class="page-header-left">
            <h3>{{title}}</h3>
            <ol class="breadcrumb">
              <li class="breadcrumb-item" v-if="user_role == 'manager'"><router-link :to="{ path: '/manager/users' }">Home</router-link></li>
              <li class="breadcrumb-item" v-else><router-link :to="{ path: '/app/dashboard' }">Home</router-link></li>
              <li class="breadcrumb-item" v-if="main">{{main}}</li>
              <li class="breadcrumb-item active" v-if="title">{{title}}</li>
            </ol>
          </div>
        </div>
        <div class="col-lg-6 text-right">
          <b-button size="lg" v-if="user_role != 'manager' && user_role != 'moderator' && add_monitor_btn == true" @click="AddMonitor" variant="success" class="btn" >Add New Monitor +</b-button>
          <!-- <Bookmark /> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Bookmark from  './bookmark'
export default {
  data(){
    return{
      user_role:'',
      add_monitor_btn:false
    } 
  },
  mounted(){
    let user  = JSON.parse(window.localStorage.getItem('userinfo'))
    this.user_role = user.role
    if(this.$router.history.current.path != '/app/monitor'){
      this.add_monitor_btn = true
    }
  },
  props: {
    title: {
      default: 'home'
    },
    main: {
      default: ''
    }
  },
  components:{
    Bookmark
  },
  methods:{
    AddMonitor(){
      // let uid = Math.floor(Math.random() * 100000);
      // if(this.$router.history.current.fullPath != '/app/monitor'){
        this.$root.$data.showAddMoinitor = true;
        this.$router.push("/app/monitor");
        
      // }
      
      // window.location.reload();
    }
  }
}
</script>
