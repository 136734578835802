import Vue from 'vue'
import App from './App.vue'

// Bootstrap Framework Component
import BootstrapVue from 'bootstrap-vue'

// Vue Router
import router from './router'

// Vue Router
import Breadcrumbs from './components/bread_crumbs'

// Vuex Store
import { store } from './store';

// i18n
import VueI18n from 'vue-i18n';

// Firebase
// import firebase from 'firebase/app';

// Vue Filters
// import Vue2Filters from 'vue2-filters'

import PxCard  from './components/Pxcard.vue'
Vue.component(PxCard.name, PxCard)

// Firebase
// import VueSweetalert2 from 'vue-sweetalert2';
// import VueFormWizard from 'vue-form-wizard';
// import VueTour from 'vue-tour'
// import Notifications from 'vue-notification'
import { Vue2Dragula } from 'vue2-dragula'
import Toasted from 'vue-toasted';
// import SmartTable from 'vuejs-smart-table'
// import * as VueGoogleMaps from 'vue2-google-maps'
import { VueMasonryPlugin } from 'vue-masonry'
import VueFeather from 'vue-feather';
import Chartist from "chartist";
// import VueApexCharts from 'vue-apexcharts'
// import carousel from 'vue-owl-carousel'
// import CKEditor from '@ckeditor/ckeditor5-vue';
// import VueIntro from 'vue-introjs';

// import { carousel } from "leaflet";
// delete Icon.Default.prototype._getIconUrl;

import en from "./locales/en.json"
import es from "./locales/es.json"

// Import Theme scss
import './assets/scss/app.scss'

// Icon.Default.mergeOptions({
//   iconRetinaUrl: require("./assets/images/vue2leaflet/marker-icon.png"),
//   iconUrl: require("./assets/images/vue2leaflet/marker-icon.png")
// });

Vue.use(VueFeather);

Vue.use(VueI18n);
const messages = { en: en, es:es };
import {defaultLocale,localeOptions} from './constants/config'
const locale =(localStorage.getItem('language') && localeOptions.filter(x=>x.id==localStorage.getItem('language')).length>0) ? localStorage.getItem('language') : defaultLocale
const i18n = new VueI18n({
  locale:locale,
  fallbackLocale:'en',
  messages
})

Vue.use(Toasted,{
  iconPack: 'fontawesome'
});
Vue.use(Vue2Dragula);

// Vue.use(VueGoogleMaps, {
//   load: {
//     key: 'API_KEY',
//     libraries: 'places',
//   },
// })

// Vue.use(Notifications)
// Vue.use(Vue2Filters)
// Vue.use(VueSweetalert2)
// Vue.use(VueFormWizard)
// Vue.use(VueTour)
Vue.use(BootstrapVue)
// Vue.use(SmartTable)
Vue.use(require('vue-chartist'))
// Vue.use(require('vue-moment'));
Vue.component('Breadcrumbs', Breadcrumbs)
Vue.use(VueMasonryPlugin)
// Vue.component('apexchart', VueApexCharts)
// Vue.use(carousel)
// Vue.use(CKEditor)
// Vue.use(VueIntro)

Vue.prototype.$Chartist = Chartist;

Vue.config.productionTip = false
// var config = {
//   apiKey: "AIzaSyCI9rT4Be1zNfEtEDRggYpjaXQju0VioGw",
//   authDomain: "dfds-c9246.firebaseapp.com",
//   databaseURL: "https://dfds-c9246.firebaseio.com",
//   projectId: "dfds-c9246",
//   storageBucket: "dfds-c9246.appspot.com",
//   messagingSenderId: "574319923169"
// };
// firebase.initializeApp(config);

// export const db = firebase.firestore();

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')