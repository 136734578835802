<template>
  <div>
    <div class="p-3">
      <b-row>
        <b-col class="col-md-5 col-12">
          <b-row class="p-3">
            <b-col class="text-center">
              <h4 class="font-weight-bold">Your Current Plan Info</h4>
            </b-col>
          </b-row>
          <div class="p-2" style="background-color: azure">
            <b-row class="p-1">
              <b-col class="text-left">
                You are using
                <span class="text-primary font-weight-bold"
                  >{{ plan_details.plan_name }} Plan</span
                >
                ({{ plan_details.monitorcount }}
                monitors, <span v-if="plan_details.plan_name == 'free'">5</span
                ><span v-else>1</span>&nbsp;minute intervals).
              </b-col>
            </b-row>
            <div class="p-2 font-weight-bold">
              <b-row>
                <b-col cols="5"> Left Messages </b-col>
                <b-col>
                  <span class="text-info">{{ plan_details.messageLeft }}</span>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="5"> Left Calls </b-col>
                <b-col cols="5"
                  ><span class="text-info">{{ plan_details.callLeft }}</span>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="5"> Allowed Monitors </b-col>
                <b-col>
                  <span
                    class="text-info"
                    v-for="monitor in plan_details.monitorTypes"
                    :key="monitor"
                  >
                    {{ monitor_type_opt[monitor] }},&nbsp;
                  </span>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="5"> Allowed Alerts </b-col>
                <b-col>
                  <span
                    class="text-info"
                    v-for="alert in plan_details.alertsTypes"
                    :key="alert"
                  >
                    {{ alert_opt[alert] }},&nbsp;
                  </span>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="5">Allowed Subuser</b-col>
                <b-col>
                  <span class="text-info">
                  {{ plan_details.subUserLimit }}
                  </span>
                </b-col>
              </b-row>
            </div>
          </div>
          <hr />
          <b-row class="p-3">
            <b-col class="text-center">
              <h4 class="font-weight-bold">Upgrade to Pro Plan</h4>
            </b-col>
          </b-row>
          <b-row class="p-1">
            <b-col class="text-center">
              <b-row>
                <b-col
                  ><span class="font-weight-bold"
                    >Select Plan you required
                  </span>
                </b-col>
              </b-row>
              <b-row class="mt-1 p-1">
                <b-col class="text-center">
                  <b-form-select
                    class="col-md-8 col-12"
                    name="type"
                    size="sm"
                    v-model="input.plan_id"
                    :options="plan_opt"
                    required
                  ></b-form-select>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col class="text-center">
              <b-button
                v-if="cancel_plan"
                variant="danger"
                @click="cancelPlan()"
              >
                <span v-if="loading">Loading... </span> Cancel previous
                Subscription
              </b-button>
              <b-button v-else variant="success" @click="CheckOut()"
                >Pay</b-button
              >
            </b-col>
          </b-row>
          <b-row class="p-3">
            <b-col class="text-center">
              <span><a href="https://pingmonk.com/pricing/" target="_blank" rel="noopener noreferrer">Click here for plan info...</a></span>
            </b-col>
          </b-row>
        </b-col>
        <b-col class="">
          <b-row class="p-3">
            <b-col class="text-center">
              <h4 class="font-weight-bold">
                What benefits does the PRO plan offer?
              </h4>
            </b-col>
          </b-row>
          <b-row class="col-md-12">
            <b-col class="text-center p-3 m-3 box-style">
              <b-row>
                <b-col class="text-center">
                  <img
                    src="../assets/images/clock/5x.svg"
                    alt="cron job icon"
                    width="50"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-row class="font-weight-bold">
                    <b-col>
                      <span style="text-decoration: line-through">
                        5 minutes</span
                      >&nbsp; 1 minute of monitoring time
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <small class="text-muted"
                        >Quicker notification! There is no longer a time delay
                        for monitoring.</small
                      >
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
            <b-col class="text-center p-3 m-3 box-style">
              <b-row>
                <b-col>
                  <img
                    src="../assets/images/clock/announcement.svg"
                    alt="cron job icon"
                    width="50"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-row class="font-weight-bold">
                    <b-col> Customizations to the status page </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <small class="text-muted"
                        >Customize or create a new status page for your
                        domain.</small
                      >
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row class="col-md-12">
            <b-col class="text-center p-3 m-3 box-style">
              <b-row>
                <b-col>
                  <img
                    src="../assets/images/clock/shild.svg"
                    alt="cron job icon"
                    width="50"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-row class="font-weight-bold">
                    <b-col> SSL certificate monitoring </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <small class="text-muted"
                        >A few days before the SSL certificate expires,
                        notifications are sent.</small
                      >
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
            <b-col class="text-center p-3 m-3 box-style">
              <b-row>
                <b-col>
                  <img
                    src="../assets/images/clock/clock.svg"
                    alt="cron job icon"
                    width="50"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-row class="font-weight-bold">
                    <b-col> Background job monitoring </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <small class="text-muted"
                        >Keep an eye on recurring requests.</small
                      >
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row class="col-md-12">
            <b-col class="text-center p-3 m-3 box-style">
              <b-row>
                <b-col>
                  <img
                    src="../assets/images/clock/more.svg"
                    alt="cron job icon"
                    width="50"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-row class="font-weight-bold">
                    <b-col> ...and more! </b-col>
                  </b-row>
                  <b-row class="">
                    <b-col>
                      <small class="text-muted"
                        >Maintenance windows, customized HTTP headers and
                        statuses, and 24-month log retention.</small
                      >
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row class="p-1" v-if="plan_details.plan_type != 1">
            <b-col class="text-center">
              <span class="link_txt text-danger" @click="cancelPlan()"
                >Click here
              </span>
              to Cancel Subscription.
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
  </div>
</template>




<script>
import UserController from "../../services/userController";
export default {
  name: "UpgradePlan",
  data() {
    return {
      input: { plan_id: null },
      plan_details: {},
      plan_list: [],
      plan_opt: [{ value: null, text: "select plan" }],
      cancel_plan: false,
      loading: false,
      monitor_type_opt: {
        1: "HTTP(s)",
        2: "Keyword",
        3: "Ping",
        4: "Port",
        5: "Heartbeat",
        6: "Page Speed",
        7: "Page Screenshot",
      },
      alert_opt: {
        1: "Email",
        2: "Skype",
        3: "Telegram",
        4: "Web push",
        5: "Webhook",
        6: "Message",
        7: "Call",
        8: "Slack",
        9: "Zappier webhook",
      },
    };
  },
  mounted() {
    this.getPlanInfo();
    this.GetPlansList();
  },
  methods: {
    async getPlanInfo() {
      let response = await UserController.getPlanInfo();
      if (response.result) {
        this.plan_details = response.message;
      }
    },
    async GetPlansList() {
      let response = await UserController.getPlanList();
      if (response.result) {
        this.plan_list = response.data;
        this.plan_list.map((t)=>{
          t.text = t.plan_name + ' ($'+t.price +' for '+ t.monitorcount +' Monitors)'
        })
        this.plan_list.map((t) => {
          this.plan_opt.push({ value: t._id, text: t.text });
        });
      }
      else{
        this.$toasted.show(response.message, {
          theme: "outline",
          position: "bottom-center",
          type: "error",
          duration: 2000,
        });
      }
    },
    async CheckOut() {
      if (this.input.plan_id) {
        let response = await UserController.checkOut(this.input);
        if (response.result) {
          window.location.replace(response.data);
        } else if (response.error_code){
          this.$toasted.show(response.message, {
            theme: "outline",
            position: "bottom-center",
            type: "error",
            duration: 2000,
          });
          this.cancel_plan = true;
        } else {
          this.$toasted.show(response.message, {
            theme: "outline",
            position: "bottom-center",
            type: "error",
            duration: 2000,
          });
        }
      } else {
        this.$toasted.show("Please select plan first", {
          theme: "outline",
          position: "bottom-center",
          type: "error",
          duration: 2000,
        });
      }
    },
    async cancelPlan() {
      if (confirm("Are you sure to cancel Subscription ?")) {
        this.loading = true;
        let response = await UserController.cancelSubcscription(this.input);
        if (response.result) {
          this.$toasted.show(response.message, {
            theme: "outline",
            position: "bottom-center",
            type: "success",
            duration: 2000,
          });
          this.cancel_plan = false;
          this.getPlanInfo();
        } else {
          this.$toasted.show(response.message, {
            theme: "outline",
            position: "bottom-center",
            type: "error",
            duration: 2000,
          });
        }
        this.loading = false;
      }
    },
  },
};
</script>


<style scoped>
.plan-bg {
  border-left: 1px solid #e9e9e9c2;
  /* background: #f2f2f2 !important; */
}
.box-style {
  /* border:1px solid; */
  border-radius: 12px;
  box-shadow: 0px 0px 8px 3px;
}
</style>